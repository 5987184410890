import { Dock } from "../index";

export function getDockPricesRange(dock: Dock): { min: number; max: number } {
  const spacePrices = dock.sections
    .flatMap((s) => s.spaces)
    .flatMap((s) => s.prices)
    .map((p) => Number(p.price));

  const defaultPrices = dock.sections
    .flatMap((s) => s.spaces)
    .map((s) => Number(s.defaultPrice));

  const prices = [...spacePrices, ...defaultPrices];

  const max = Math.max(...prices);
  const min = Math.min(...prices);
  return { min, max };
}

export function getDockMinPrice(dock: Dock): number {
  const spacePrices = dock.sections
    .flatMap((s) => s.spaces)
    .flatMap((s) => s.prices)
    .map((p) => Number(p.price));

  const defaultPrices = dock.sections
    .flatMap((s) => s.spaces)
    .map((s) => Number(s.defaultPrice));

  const prices = [...spacePrices, ...defaultPrices];

  if (prices.length === 0) {
    prices.push(0);
  }

  return Math.min(...prices);
}
