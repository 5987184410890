import styled from "styled-components";
import { useStore, useStoreMap, useUnit } from "effector-react";

import { Form } from "shared/lib/form";
import {
  DateField,
  SelectField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import {
  convertDateToApi,
  displayTime,
  removeTimeZone,
} from "shared/lib/dayjs-ext";
import { AddBoat } from "@client-app/features/manage-boats/add";
import { $viewer } from "@client-app/entities/viewer";
import {
  $noAuthUserBoat,
  BoatSelectorField,
  NoAuthBoatSelector,
} from "@client-app/entities/boats";
import { ModalLink } from "@client-app/shared/lib/modal-routes";
import {
  TimeSlotIsAlreadyTakenModal,
  SomethingWrongModal,
} from "@client-app/entities/reservation";

import {
  $availableDates,
  $maxAvailableDurations,
  $availableTimes,
  $dock,
  $duration,
  $isFormSubmitting,
  $priceInfo,
  $selectedDate,
  $selectedTime,
  loginAndConfirmBookingClicked,
  reserveDockForm,
  selectedMonthChanged,
} from "../model/create-reservation";
import { ReasonField } from "./reason-field";

export function ReserveDockForm(): JSX.Element | null {
  const [viewer, noAuthUserBoat, isFormSubmitting, dock] = useUnit([
    $viewer,
    $noAuthUserBoat,
    $isFormSubmitting,
    $dock,
  ]);

  const timeSelected = useStoreMap($selectedTime, Boolean);
  if (!dock) return null;

  return (
    <ReserveDockFormRoot as={Form} form={reserveDockForm}>
      <Title>Reservation</Title>
      <Boat>
        {viewer ? (
          <AddBoat>
            {({ openAddBoatModal }) => (
              <BoatSelectorField
                name="boatId"
                label="Saved boats"
                onAddBoatClick={openAddBoatModal}
              />
            )}
          </AddBoat>
        ) : (
          <>
            <AddBoat>
              {({ openAddBoatModal }) => (
                <NoAuthBoatSelector
                  name="boatId"
                  label="Saved boats"
                  boat={noAuthUserBoat}
                  onClick={openAddBoatModal}
                />
              )}
            </AddBoat>
          </>
        )}
      </Boat>
      <DurationSelector />
      <DateSelector />
      <TimeSelector />

      <ReasonField name="reason" />
      <Price />
      {viewer ? (
        <SubmitButton disabled={!timeSelected} isSubmitting={isFormSubmitting}>
          Confirm booking
        </SubmitButton>
      ) : (
        <ModalLink modal="login">
          <SubmitButton
            disabled={!timeSelected}
            isSubmitting={false}
            onClick={loginAndConfirmBookingClicked}
          >
            Login and confirm booking
          </SubmitButton>
        </ModalLink>
      )}
      <TimeSlotIsAlreadyTakenModal />
      <SomethingWrongModal dock={dock} />
    </ReserveDockFormRoot>
  );
}

function DurationSelector() {
  const availableDurationsOptions = useStoreMap(
    $maxAvailableDurations,
    (maxDurations) =>
      new Array(maxDurations)
        .fill(0)
        .map((_, index) => index + 1)
        .map((duration) => ({
          value: duration,
          label: `${duration} hr`,
        }))
  );

  const [viewer, noAuthUserBoat] = useUnit([$viewer, $noAuthUserBoat]);

  return (
    <SelectField
      label="Duration"
      name="duration"
      disabled={!noAuthUserBoat && !viewer}
      options={availableDurationsOptions}
    />
  );
}

function DateSelector() {
  const [availableDates] = useUnit([$availableDates]);
  const durationSelected = useStoreMap($duration, Boolean);

  return (
    <DateField
      name="date"
      label="Date"
      disabled={!durationSelected}
      onMonthChange={selectedMonthChanged}
      shouldDisableDate={(date) => {
        return !availableDates[convertDateToApi(date)];
      }}
    />
  );
}

function TimeSelector() {
  const availableTimesOptions = useStoreMap($availableTimes, (times) =>
    times.map((time) => {
      const timeFrom = displayTime(removeTimeZone(time.from));
      const timeTo = displayTime(removeTimeZone(time.to));

      const label = `${timeFrom} - ${timeTo}`;

      return {
        value: time,
        label,
        key: label,
      };
    })
  );

  const dateSelected = useStoreMap($selectedDate, Boolean);

  return (
    <SelectField
      disabled={!dateSelected}
      label="Time"
      name="time"
      options={availableTimesOptions}
    />
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0a1128;
`;

function Price() {
  const price = useStore($priceInfo);

  if (!price) return null;

  return (
    <PriceRoot>
      <BlockTitle>Price</BlockTitle>
      <PriceRow>
        <PriceLabel>Per Hour</PriceLabel>
        <Value>${price.dockSpacePricePerHour}</Value>
      </PriceRow>
      <PriceBlock>
        <PriceRow>
          <PriceLabel>
            Hours - <Hours> {price.hours}</Hours>
          </PriceLabel>
          <Value>${price.dockSpacePrice}</Value>
        </PriceRow>
        <PriceRow>
          <PriceLabel>
            Tax
            <PriceDetail>(Mooring Tax {price.taxRate}%)</PriceDetail>
          </PriceLabel>
          <Value>${price.tax}</Value>
        </PriceRow>
      </PriceBlock>
      <PriceRow>
        <PriceLabel>Booking Fee</PriceLabel>
        <Value>${price.bookingFee}</Value>
      </PriceRow>
      {price.charterFee && (
        <PriceRow>
          <PriceLabel>Charter Fee</PriceLabel>
          <Value>${price.charterFee}</Value>
        </PriceRow>
      )}
      <PriceTotalRow>
        Total <Value>${price.totalPrice}</Value>
      </PriceTotalRow>
    </PriceRoot>
  );
}

const BlockTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #0a1128;
`;

const Hours = styled.span`
  color: #5e88ef;
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #0a1128;
`;

const PriceLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2px;
  font-weight: 400;
  font-size: 16px;
`;

const PriceDetail = styled(PriceLabel)`
  color: #3bb7b6;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  color: #5e616c;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  & > * {
    &:not(:first-child) {
      margin-left: 6px;
    }
  }
`;

const PriceTotalRow = styled(PriceRow)`
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #0a1128;

  ${Value} {
    font-weight: 600;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    color: #5e88ef;
  }
`;

const PriceRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Boat = styled.div``;

const ReserveDockFormRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  ${Title} {
    margin-bottom: 24px;
  }

  ${BlockTitle} {
    margin-top: 24px;
  }

  ${Boat} {
    margin-bottom: 17px;
  }

  ${ModalLink} {
    display: block;
    text-decoration: none;

    ${SubmitButton} {
      width: 100%;
    }
  }
`;
