import { createGate } from "effector-react";
import {
  attach,
  combine,
  createEvent,
  createStore,
  Store,
  Event,
  sample,
} from "effector";
import { Dayjs } from "dayjs";
import { and } from "patronum";

import { Dock } from "shared/api/types";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import {
  fromApi,
  getDockCollection,
  getListFromApi,
  getDockFilterSchedule,
} from "shared/api";
import { convertDateToApi } from "shared/lib/dayjs-ext/dayjs-ext";

import { $cities } from "@client-app/entities/cities";
import {
  $clientsBoats,
  $clientMainBoat,
  $noAuthUserBoat,
} from "@client-app/entities/boats";

import { getFirstDayOfMonth } from "shared/lib/dayjs-ext";

interface Filters {
  itemsPerPage: number;
  search: string | null;
}

export const pageGate = createGate();

export const $docksList = createStore<Dock[]>([]);
export const $docksTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const [$searchQuery, searchQueryChanged] =
  createRouterParamsState<string>({
    name: "search",
    defaultValue: "",
  });
export const [$selectedCityURI, selectedCityIdChanged] =
  createRouterParamsState<string>({
    name: "cityId",
    defaultValue: "",
  });
export const [$selectedBoatURI, selectedBoatIdChanged] =
  createRouterParamsState<string>({
    name: "boatId",
    defaultValue: "",
  });

export const [$date, dateChanged] = createRouterParamsState<Dayjs | null>({
  name: "date",
  defaultValue: null,
});

const $selectedMonthFirstDate = createStore<string | null>(
  getFirstDayOfMonth()
);
export const $availableDaysItems = createStore<Record<string, boolean>>({});

export const [$duration, durationChanged] = createRouterParamsState<number>({
  name: "duration",
  defaultValue: 1,
});

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const dockSelected = createEvent<number>();
export const selectedMonthChanged = createEvent<Dayjs>();

const $selectedCity = combine($selectedCityURI, $cities, (uri, cities) =>
  cities.find((city) => city["@id"] === uri)
);
const $selectedCityId = $selectedCity.map((city) => (city ? city.id : ""));

const $selectedBoat = combine($selectedBoatURI, $clientsBoats, (uri, boats) =>
  boats.find((boat) => boat["@id"] === uri)
);

const $boatLoa = combine(
  $selectedBoat,
  $noAuthUserBoat,
  (selectedBoat, noAuthUserBoat) => {
    return (selectedBoat ?? noAuthUserBoat)?.loa ?? null;
  }
);

const $boatBeam = combine(
  $selectedBoat,
  $noAuthUserBoat,
  (selectedBoat, noAuthUserBoat) => {
    return (selectedBoat ?? noAuthUserBoat)?.beam ?? null;
  }
);

const $filters = combine({
  itemsPerPage: $itemsPerPage,
  keyword: $searchQuery,
  cityId: $selectedCityId,
  loa: $boatLoa,
  hours: $duration,
  date: $date.map((date) => (date ? convertDateToApi(date) : void 0)),
});

const getDockCollectionFx = attach({
  // @ts-ignore
  effect: getListFromApi(getDockCollection),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

const getAvailableDaysFx = attach({
  effect: fromApi(getDockFilterSchedule),
  mapParams: (query) => ({ query }),
});

export const $isDocksLoading = getDockCollectionFx.pending;

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);
$selectedMonthFirstDate.on(selectedMonthChanged, (_, date) =>
  getFirstDayOfMonth(date)
);
// @ts-ignore
$availableDaysItems.on(getAvailableDaysFx.doneData, (_, newDates) => newDates);

sample({
  // @ts-ignore
  clock: [pageGate.open, $clientsBoats],
  source: $clientMainBoat.map((boat) => boat?.["@id"]),
  target: $selectedBoatURI,
});

sample({
  clock: [pageGate.open, $duration, $selectedMonthFirstDate, $boatLoa],
  source: {
    loa: $boatLoa,
    hours: $duration,
    beam: $boatBeam,
    monthStartDate: $selectedMonthFirstDate,
  },
  filter: and($duration, $selectedMonthFirstDate, $boatLoa),
  target: getAvailableDaysFx,
});

spreadListDataData({
  $items: $docksList,
  $totalCount: $docksTotalCount,
  effect: getDockCollectionFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  // @ts-ignore
  effect: getDockCollectionFx,
  gate: pageGate,
});

function createRouterParamsState<T>(options: {
  name: string;
  defaultValue: T;
}): [Store<T>, Event<T>] {
  const paramChanged = createEvent<T>();
  const $param = createStore<T>(options.defaultValue);
  $param.on(paramChanged, (_, value) => value);

  return [$param, paramChanged];
}
