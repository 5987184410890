import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Collapse } from "@mui/material";
import { useUnit } from "effector-react";

import {
  ReservationCard,
  SomethingWrongModal,
  ReservationSuccessModal,
} from "@client-app/entities/reservation";

import { mediaPhone, useMedia } from "shared/ui/styles/media";

import { BillingAddressForm } from "./billing-form";
import { AddonsForm } from "./addons-form";
import { PaymentMethodForm } from "./payment-method-form";
import { $addonsSubmitted } from "../model/addons";
import { $reservation, $hasPaymentIntent } from "../model/_base";
import { $billingSubmitted } from "../model/billing";

export function ReservationPayment(): JSX.Element {
  const [reservation, hasPaymentIntent, addonsSubmitted, billingSubmitted] =
    useUnit([
      $reservation,
      $hasPaymentIntent,
      $addonsSubmitted,
      $billingSubmitted,
    ]);

  const { isPhone } = useMedia();

  if (!reservation) {
    return <></>;
  }

  return (
    <>
      <ReservationPaymentRoot>
        <PaymentsSteps>
          <PaymentAccordion
            title="1. Addons"
            isOpened={!addonsSubmitted && !hasPaymentIntent}
            filled={addonsSubmitted}
          >
            <AddonsForm />
          </PaymentAccordion>

          <PaymentAccordion
            title="2. Billing address"
            isOpened={addonsSubmitted && !billingSubmitted && !hasPaymentIntent}
            filled={billingSubmitted}
          >
            <BillingAddressForm />
          </PaymentAccordion>

          <PaymentAccordion
            title="3. Payment"
            isOpened={billingSubmitted || hasPaymentIntent}
          >
            <PaymentMethodForm />
          </PaymentAccordion>
        </PaymentsSteps>

        <ReservationCard
          reservation={reservation}
          nonInteractive
          hidePrice={isPhone}
        />
      </ReservationPaymentRoot>

      <SomethingWrongModal dock={reservation.dock} />
      <ReservationSuccessModal
        dock={reservation.dock}
        reservation={reservation}
      />
    </>
  );
}

interface AccordionProps {
  title: ReactNode;
  children: ReactNode;
  isOpened: boolean;
  filled?: boolean;
}

export function PaymentAccordion(props: AccordionProps): JSX.Element {
  return (
    <AccordionRoot isOpened={props.isOpened} filled={props.filled}>
      <AccordionTitle>{props.title}</AccordionTitle>
      <Collapse in={props.isOpened}>
        <AccordionContent>{props.children}</AccordionContent>
      </Collapse>
    </AccordionRoot>
  );
}

const AccordionTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #afb9bb;
`;

const AccordionContent = styled.div`
  padding-top: 24px;
`;

const AccordionRoot = styled.div<{ isOpened: boolean; filled: boolean }>`
  border-radius: 12px;
  padding: 16px;

  background: #f5fafa;

  ${(props) =>
    props.filled &&
    css`
      background: #f1f3fa;
    `}

  ${AccordionTitle} {
    ${(props) =>
      (props.filled || props.isOpened) &&
      css`
        color: #0a1128;
      `}
  }
`;

const PaymentsSteps = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;

const ReservationPaymentRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  align-items: start;
  grid-gap: 20px;

  ${mediaPhone} {
    display: flex;
    flex-direction: column-reverse;
  }
`;
